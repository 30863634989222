import Post from "../post";

export default function IndexPage(){
    return(
        <>
            <Post />
            <Post />
            <Post />
        </>
    );
}